<template>
  <div class="flex flex-col gap-4 p-4 bg-gray-50 min-h-screen">
    <!-- Search Header -->
    <div class="flex flex-col sm:flex-row items-center justify-between bg-white p-4 rounded-lg shadow-sm gap-4">
      <h2 class="text-lg font-semibold text-gray-700">Mandate</h2>
      <div class="relative w-full sm:w-64">
        <input v-model="searchValue" @input="handleSearchInput" type="text" placeholder="Kerko..."
          class="pl-8 pr-4 py-2 border rounded-lg w-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-200" />
        <i class="fa-solid fa-magnifying-glass absolute left-3 top-3 text-gray-400 text-sm"></i>
      </div>
      <select v-model="selectedType" @change="handleTypeFilter"
        class="w-40 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:outline-none">
        <option value="">Të gjitha</option>
        <option value="1">Arketime</option>
        <option value="2">Pagesa</option>
      </select>
    </div>

    <!-- Table Container -->
    <div class="bg-white rounded-lg shadow-sm overflow-hidden">
      <div class="overflow-x-auto">
        <table class="w-full min-w-[800px]">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase sticky left-0 bg-gray-50 z-10">
                Tipi i mandatit</th>
              <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Klienti</th>
              <th class="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase">Vlera</th>
              <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Krijuar nga</th>
              <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Krijuar me</th>
              <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Koment</th>
              <th class="px-4 py-3 text-right sticky right-0 bg-gray-50 z-10">
                <button @click="creditnoteStore.toggle_create_new()"
                  class="p-1.5 bg-green-500 hover:bg-green-600 text-white rounded-lg text-sm transition-colors whitespace-nowrap">
                  <i class="fa-solid fa-plus mx-1"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="(creditnote, index) in slicedCreditnotes" :key="index"
              class="hover:bg-gray-50 transition-colors">
              <td class="px-4 py-3 text-xs text-gray-700 sticky left-0 bg-white uppercase font-bold">
                {{ creditnote.type_name }}
              </td>
              <td class="px-4 py-3 text-sm text-gray-700">{{ creditnote.customer_name }}</td>
              <td class="px-4 py-3 text-sm text-gray-700 text-right">
                {{ formatAmount(creditnote.amount) }}
              </td>
              <td class="px-4 py-3 text-sm text-gray-700">{{ creditnote.created_by_name }}</td>
              <td class="px-4 py-3 text-sm text-gray-700">{{ diaryStore.formatDate(creditnote.created_at) }}</td>
              <td colspan="2" class="px-4 py-3 text-sm text-gray-700 max-w-[200px] truncate italic">{{
                creditnote.comment }}</td>
              <!-- <td class="px-4 py-3 text-right sticky right-0 bg-white">
                <button class="text-gray-400 hover:text-blue-500 transition-colors">
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
              </td> -->
            </tr>
            <tr v-if="slicedCreditnotes.length === 0">
              <td colspan="7" class="px-4 py-6 text-center text-gray-500">
                Nuk ka rezultate për të shfaqur
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <div class="px-4 py-3 border-t border-gray-200">
        <div class="flex items-center justify-between flex-wrap gap-4">
          <span class="text-sm text-gray-500 font-thin">
            Shfaqen {{ slicedCreditnotes.length }} nga {{ creditnoteStore.filtered_creditnotes.length }} rezultate
          </span>
          <div class="flex items-center gap-4" v-if="totalPages > 1">
            <span class="text-sm text-gray-500">
              Faqja {{ currentPage }} nga {{ totalPages }}
            </span>
            <div class="flex gap-2">
              <button @click="previousPage" :disabled="currentPage === 1"
                class="px-3 py-1.5 border rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50">
                <i class="fa-solid fa-chevron-left"></i>
              </button>
              <button @click="nextPage" :disabled="currentPage === totalPages"
                class="px-3 py-1.5 border rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50">
                <i class="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Modal -->
    <div v-if="creditnoteStore.show_create_creditnote"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      @click.self="creditnoteStore.show_create_creditnote = false; creditnoteStore.clear_selected_customer(); ">
      <div class="bg-white rounded-xl w-full max-w-2xl flex flex-col max-h-[90vh]">
        <!-- Modal Header -->
        <div class="p-4 border-b flex items-center justify-between">
          <h3 class="text-lg font-semibold">Krijo mandat të ri</h3>
          <button @click="creditnoteStore.show_create_creditnote = false; creditnoteStore.clear_selected_customer();" class="text-gray-400 hover:text-gray-500">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="p-4 overflow-y-auto flex-1">
          <div class="grid grid-cols-1 gap-4">
            <!-- Customer Search -->
            <div class="space-y-1">
              <label class="text-sm font-medium text-gray-700">Klienti</label>
              <div class="flex gap-2">
                <div class="relative flex-1">
                  <input v-model="creditnoteStore.search_customer_val" type="text"
										:disabled="creditnoteStore.selected_customer"
                    :placeholder="creditnoteStore.selected_customer ? creditnoteStore.selected_customer.name : 'Kerko klient'"
                    class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:outline-none" />
                  <div v-if="creditnoteStore.search_customer_val.length > 0 && !creditnoteStore.selected_customer"
                    class="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
                    <div v-for="(customer, index) in creditnoteStore.filtered_customers" :key="index"
                      @click="creditnoteStore.select_customer(customer)"
                      class="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm">
                      {{ customer.name }}
                    </div>
                  </div>
                </div>
                <!-- Inline reset button -->
                <button v-if="creditnoteStore.selected_customer" @click="creditnoteStore.clear_selected_customer()"
                  class="px-3 py-2 text-sm text-gray-500 hover:text-gray-700 border rounded-lg hover:bg-gray-50">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
						<!-- unpaid invoices table --> 
						<div v-if="creditnoteStore.new_creditnote?.unpaid_invoices?.length > 0">
							<h2 class="text-lg font-semibold mb-4">Faturat e papaguara</h2>
							<div class="overflow-x-auto max-h-96">
								<table class="min-w-full bg-white border border-gray-200">
									<thead>
										<tr class="bg-gray-50">
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Numër</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Totale</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paguar</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Papaguar</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex gap-1">
												<input type="checkbox" @click="creditnoteStore.toggle_select_all_invoices" />
											</th>
										</tr>
									</thead>
									<tbody class="divide-y divide-gray-200">
										<tr v-for="invoice in creditnoteStore.new_creditnote?.unpaid_invoices" :key="invoice.invoice_id" class="hover:bg-gray-50">
											<td class="px-4 py-2 text-sm text-gray-700">{{ invoice.invoice_id }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.total_amount) }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.total_paid) }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.unpaid_amount) }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">
												<input type="checkbox" v-model="invoice.selected" @click="creditnoteStore.total_amount_unpaid_selected_invoices()" /> 
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div v-if="creditnoteStore.new_creditnote?.unpaid_invoices?.length === 0 && creditnoteStore.selected_customer">
							<p class="text-sm text-blue-500">
								<i class="fa-solid fa-info-circle text-blue-400 mr-1"></i>
								Nuk ka fatura të papaguara për këtë klient
							</p> 
						</div>
            <!-- Amount and Type -->
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="space-y-1">
                <label class="text-sm font-medium text-gray-700">Vlera e mandatit</label>
                <input :value="formatAmount(creditnoteStore.new_creditnote.amount)" @input="handleAmountInput"
                  type="text" placeholder="Shkruaj vlerën"
                  class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:outline-none" />
              </div>
              <div class="space-y-1">
                <label class="text-sm font-medium text-gray-700">Tipi i mandatit</label>
                <select v-model="creditnoteStore.new_creditnote.creditnote_type"
                  class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:outline-none">
                  <option v-for="(type, index) in creditnoteStore.creditnote_types" :key="index" :value="type.id">
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Comments -->
            <div class="space-y-1">
              <label class="text-sm font-medium text-gray-700">Koment</label>
              <textarea v-model="creditnoteStore.new_creditnote.comment" rows="3"
                class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-200 focus:outline-none"></textarea>
            </div>

						<div class="flex gap-1">
							<div class="">
								Do të likuidohen: 
								<table class="min-w-full bg-white border border-gray-200">
									<thead>
										<tr class="bg-gray-50">
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Numër</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Totale</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paguar</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Papaguar</th>
											<th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mbetet</th>
										</tr>
									</thead>
									<tbody class="divide-y divide-gray-200">
										<tr v-for="invoice in creditnoteStore.creditnote_will_pay_fully_or_partially_invoices" :key="invoice.invoice_id" class="hover:bg-gray-50">
											<td class="px-4 py-2 text-sm text-gray-700">{{ invoice.invoice_id }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.total_amount) }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.total_paid) }}</td>
											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.unpaid_amount) }}</td>

											<td class="px-4 py-2 text-sm text-gray-700">{{ formatAmount(invoice.remaining_amount) }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<!-- Submit Button -->
							<button @click="start_create_new_creditnote"
								class="w-full py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed">
								Krijo Mandat{{ creditnoteStore.new_creditnote?.unpaid_invoices?.filter(invoice => invoice.selected).length > 1 ? 'e' : '' }}
							</button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCreditnoteStore } from '@/stores/creditnote'
import { useDiaryStore } from '@/stores/diary'
import { computed, ref, watch } from 'vue'

const creditnoteStore = useCreditnoteStore()
const diaryStore = useDiaryStore()

const currentPage = ref(1)
const itemsPerPage = 10
const searchValue = ref('')
const selectedType = ref('')

const totalPages = computed(() => 
  Math.ceil(creditnoteStore.filtered_creditnotes.length / itemsPerPage)
)

const slicedCreditnotes = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage
  const end = start + itemsPerPage
  return creditnoteStore.filtered_creditnotes.slice(start, end)
})

watch(() => creditnoteStore.filtered_creditnotes, () => {
  currentPage.value = 1
})

function handleSearchInput() {
  creditnoteStore.search_creditnote_val = searchValue.value
  currentPage.value = 1
}

function previousPage() {
  if (currentPage.value > 1) currentPage.value--
}

function nextPage() {
  if (currentPage.value < totalPages.value) currentPage.value++
}

function handleTypeFilter() {
  creditnoteStore.filter_by_type = selectedType.value
  currentPage.value = 1
}

watch(searchValue, () => {
  selectedType.value = ''
})

const start_create_new_creditnote = async () => {
  try {
    const response = await creditnoteStore.create_new_creditnote()
    if (response.message === 'success') {
      // Show success notification
      if (typeof toast !== 'undefined') {
        // toast.success('Mandati u krijua me sukses!')
      }
      // Reset form
      creditnoteStore.show_create_creditnote = false
    }
  } catch (error) {
    console.error('Error creating credit note:', error)
    if (typeof toast !== 'undefined') {
      // toast.error('Gabim në krijimin e mandatit!')
    }
  }
}

function formatAmount(value) {
  if (!value) return ''
  return new Intl.NumberFormat('en-US').format(value)
}

function handleAmountInput(event) {
  // Remove any non-digit characters except decimal point
  const value = event.target.value.replace(/[^\d.]/g, '')
  // Convert to number and update store
  creditnoteStore.new_creditnote.amount = value ? parseFloat(value) : null
}
</script>

<style>
/* Custom scrollbar for dropdown */
.dropdown-box-shadow::-webkit-scrollbar {
  width: 6px;
}

.dropdown-box-shadow::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dropdown-box-shadow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dropdown-box-shadow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Sticky header and columns */
th.sticky,
td.sticky {
  position: sticky;
  z-index: 10;
}

th.sticky {
  z-index: 20;
}
</style>
